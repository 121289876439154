import React from 'react';
import ReactQuill from 'react-quill';
import {StringMap} from 'quill';
import 'react-quill/dist/quill.bubble.css';
import {Box} from '@mui/material';

export interface GreenChoiceDescriptionEditorProps {
	initialValue: string;
	onChange(value: string): void;
}

export function GreenChoiceDescriptionEditor(props: GreenChoiceDescriptionEditorProps) {
	const {initialValue, onChange} = props;

	const modules: StringMap = {
		toolbar: [
			['bold', 'italic', 'underline', 'blockquote'],
			[{size: ['small', false, 'large', 'huge']}],
		],
	};

	return (
		<Box sx={{fontSize: '1rem', minWidth: '1rem'}}>
			<ReactQuill
				theme="bubble"
				value={initialValue}
				onChange={(value) => onChange(value)}
				modules={modules}
			/>
		</Box>
	);
}
