import React, {useContext, useState} from 'react';
import {SettingsListSwitch} from '@app/shared';
import {useTranslation} from 'react-i18next';
import {GreenChoiceService} from '@app/services';
import {AlertSeverity} from '@app/model';
import {SnackbarContext} from '@app/context';
import {Form, Formik} from 'formik';
import {Box, InputAdornment, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {NumericFormat} from 'react-number-format';

interface GreenChoiceDonationSettingsProps {
	showDonationAmountInEmail: boolean | undefined;
	donationAmountInCents: number;
	setDonationAmaountInCents: React.Dispatch<React.SetStateAction<number>>;
}

export function GreenChoiceDonationSettings({showDonationAmountInEmail, donationAmountInCents, setDonationAmaountInCents} : GreenChoiceDonationSettingsProps) {
	const {t} = useTranslation(['greenChoice']);

	const [isSwitchOn, setIsSwitchOn] = useState<boolean>(!!showDonationAmountInEmail);
	const [isSwitchLoading, setIsSwitchLoading] = useState<boolean>(false);
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
	const [floatAmount, setFloatAmount] = useState<number>(donationAmountInCents / 100);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);
	const {i18n} = useTranslation();

	async function handleShowDonationAmountSwitchChange() {
		setIsSwitchLoading(true);
		await greenChoiceService.updateGreenChoiceShowDonationAmount(!isSwitchOn);
		setIsSwitchOn(!isSwitchOn);
		showMessage(t('common:save.saved'), AlertSeverity.SUCCESS);
		setIsSwitchLoading(false);
	}

	async function onSubmitAmount(_values: {amount: number}) {
		const amountInCents = floatAmount * 100;

		setIsSubmitButtonLoading(true);
		setIsSwitchLoading(true);
		greenChoiceService
			.updateGreenChoiceDonationAmount(amountInCents)
				.then(() => {
					setIsSwitchLoading(false);
					setIsSubmitButtonLoading(false);
					setDonationAmaountInCents(amountInCents);
					showMessage(t('common:save.saved'), AlertSeverity.SUCCESS);
				})
			.catch(() => {
				showMessage(t('common:save.error'), AlertSeverity.ERROR);
				setIsSwitchLoading(false);
				setIsSubmitButtonLoading(false);
			});
	}

	return (
		<>
			<SettingsListSwitch
				shouldSwitchBeDisabled={isSwitchLoading}
				isSwitchOn={isSwitchOn}
				switchLabel={t('greenChoice:settings.miscSettings.showDonationAmountInEmail')}
				handleSwitchChange={handleShowDonationAmountSwitchChange}
			/>
			<Formik
				initialValues={{amount: donationAmountInCents as number / 100}}
				onSubmit={onSubmitAmount}
				validateOnChange={false}
			>
				{(formikProps) => (
					<Form>
						<Stack spacing={1} ml={2} mr={3} sx={{maxWidth: '380px'}}>
							<NumericFormat
								size="small"
								name="amount"
								label={t('greenChoice:settings.miscSettings.donationAmount')}
								customInput={TextField}
								onValueChange={(values, _sourceInfo) => values.floatValue ? setFloatAmount(values.floatValue) : null}
								onChange={formikProps.handleChange}
								value={formikProps.values.amount}
								error={Boolean(formikProps.errors.amount)}
								helperText={formikProps.errors.amount}
								decimalScale={2}
								fixedDecimalScale={true}
								decimalSeparator={i18n.language.startsWith('de') ? "," : '.'}
								variant="outlined"
								sx={{input: {textAlign: "right"}}}
								InputProps={{
									endAdornment: <InputAdornment position="end">€</InputAdornment>,
								}}
							/>
							<Box display="flex" justifyContent="right" mt={1}>
								<LoadingButton
									loading={isSubmitButtonLoading}
									startIcon={<SaveRoundedIcon />}
									variant="contained"
									type="submit"
									size="small"
									disabled={formikProps.values.amount === donationAmountInCents / 100}
								>
									{t('common:button.save')}
								</LoadingButton>
							</Box>
						</Stack>
					</Form>
				)}
			</Formik>
		</>
	);
}