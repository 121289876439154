import {Box, Button} from '@mui/material';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import DescSnippet from '../DescSnippet';
import {Translation} from 'react-i18next';

interface VendorLandingPageProps {
	vendorId: string;
	secret: string;
}

export default class VendorLandingPage extends Component<VendorLandingPageProps, unknown> {

	render() {
		return (
			<Translation ns={['pms']}>
				{(t) => (
					<div
						style={{
							margin: 'auto',
							width: '300px',
							display: 'flex',
							flexDirection: 'column',
							padding: '20px',
						}}
					>
						<Box
							component="img"
							src="/assets/logo_login_page.png"
							alt="Roomgrid logo"
							mb={10}
							mt="20px"
						/>
						<Button
							component={Link}
							to={'/login/connect/' + this.props.vendorId}
							variant="contained"
							color="primary"
						>
							{t('landing.loginButton')}
						</Button>
						<DescSnippet content={t('landing.notRegisteredTitle')}/>
						<Button
							component={Link}
							to={
								'/register/connect/' + this.props.vendorId + '/' + this.props.secret
							}
							variant="contained"
							color="primary"
						>
							{t('landing.registerButton')}
						</Button>
					</div>
				)}
			</Translation>
		);
	}
}
