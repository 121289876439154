import React from 'react';
import {Container, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function Privacy() {
	const theme = useTheme();
	const {t} = useTranslation(['privacyPolicy', 'legalNotice']);

	return (
		<Container maxWidth="md" sx={{mt: 8}}>
			<Typography variant="h4" component="h3" sx={{mb: 2, color: theme.palette.primary.dark}}>
				{t('privacyPolicy:title')}
			</Typography>
			<Typography variant="h5" component="h4">
				{t('privacyPolicy:overview')}
			</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:generalInfo')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:generalInfoText')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:dataRecording')}
			</Typography>
			<Typography variant="subtitle2">{t('privacyPolicy:whoIsResponsible')}</Typography>
			<Typography paragraph>{t('privacyPolicy:whoIsResponsibleText')}</Typography>
			<Typography variant="subtitle2">{t('privacyPolicy:howDoWeRecord')}</Typography>
			<Typography paragraph>{t('privacyPolicy:howDoWeRecordText1')}</Typography>
			<Typography paragraph>{t('privacyPolicy:howDoWeRecordText2')}</Typography>
			<Typography variant="subtitle2">{t('privacyPolicy:whatPurposes')}</Typography>
			<Typography paragraph>{t('privacyPolicy:whatPurposesText')}</Typography>
			<Typography variant="subtitle2">{t('privacyPolicy:whatRights')}</Typography>
			<Typography paragraph>{t('privacyPolicy:whatRightsText1')}</Typography>
			<Typography paragraph>{t('privacyPolicy:whatRightsText2')}</Typography>
			<Typography variant="h5" component="h4">
				{t('privacyPolicy:hosting')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:hostingText1')}</Typography>
			<Typography paragraph>Hetzner</Typography>
			<Typography paragraph>{t('privacyPolicy:hostingText2')}</Typography>
			<Typography paragraph>
				{t('privacyPolicy:hostingText3')}{' '}
				<a
					href="https://www.hetzner.com/de/rechtliches/datenschutz"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://www.hetzner.com/de/rechtliches/datenschutz
				</a>
				.
			</Typography>
			<Typography paragraph>{t('privacyPolicy:hostingText4')}</Typography>
			<Typography variant="h5" component="h4">
				{t('privacyPolicy:generalAndMandatoryInfo')}
			</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:dataProtection')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:dataProtectionText1')}</Typography>
			<Typography paragraph>{t('privacyPolicy:dataProtectionText2')}</Typography>
			<Typography paragraph>{t('privacyPolicy:dataProtectionText3')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:responsibleParty')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:responsiblePartyText1')}</Typography>{' '}
			<Typography paragraph>
				Thoesch GmbH
				<br />
				Mühlgasse 7
				<br />
				82335 Berg
				<br />
				<br />
				{t('legalNotice:commercialRegister')}: HRB 188126
				<br />
				{t('legalNotice:registerCourt')}: Amtsgericht München
				<br />
				<br />
				{t('legalNotice:representedBy')}
				<br />
				Christian Ey
			</Typography>
			<Typography paragraph>
				{t('legalNotice:phone')}: +43 664 1622960
				<br />
				{t('privacyPolicy:email')}: support@thoesch-conversational.ai
			</Typography>
			<Typography paragraph>{t('privacyPolicy:responsiblePartyText2')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:storageDuration')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:storageDurationText')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:legalBasis')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:legalBasisText1')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:consentRevocation')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:consentRevocationText')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:rightToObject')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:rightToObjectText1')}</Typography>
			<Typography paragraph>{t('privacyPolicy:rightToObjectText2')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:rightToComplain')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:rightToComplainText')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:rightToDataPortability')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:rightToDataPortabilityText')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:informationAboutData')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:informationAboutDataText')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:rightToRestrictions')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:rightToRestrictionsText1')}</Typography>
			<ul>
				<li>{t('privacyPolicy:rightToRestrictionsList1')}</li>
				<li>{t('privacyPolicy:rightToRestrictionsList2')}</li>
				<li>{t('privacyPolicy:rightToRestrictionsList3')}</li>
				<li>{t('privacyPolicy:rightToRestrictionsList4')}</li>
			</ul>
			<Typography paragraph>{t('privacyPolicy:rightToRestrictionsText2')}</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:sslTls')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:sslTlsText1')}</Typography>
			<Typography paragraph>{t('privacyPolicy:sslTlsText2')}</Typography>
			<Typography variant="h5" component="h4">
				{t('privacyPolicy:recordingOfData')}
			</Typography>
			<Typography variant="h6" component="h5">
				{t('privacyPolicy:requestByMail')}
			</Typography>
			<Typography paragraph>{t('privacyPolicy:requestByMailText1')}</Typography>
			<Typography paragraph>{t('privacyPolicy:requestByMailText2')}</Typography>
			<Typography paragraph>{t('privacyPolicy:requestByMailText3')}</Typography>
		</Container>
	);
}
