import axios, {AxiosResponse} from 'axios';
import {AbstractService} from './AbstractService';
import {
	activeGreenChoiceData,
	ActiveGreenChoiceData,
	greenChoiceActive,
	GreenChoiceCutoff,
	greenChoiceCutoff,
	GreenChoiceCutoffMode,
	GreenChoiceDescription,
	greenChoiceDescription,
	greenChoiceDescriptionGuest,
	GreenChoiceDescriptionOption,
	greenChoiceDigitalVoucherPin,
	greenChoiceDigitalVoucherPinPublic,
	greenChoiceEmail,
	greenChoiceEmailTime,
	greenChoiceGuest,
	GreenChoiceGuestData,
	greenChoiceGuestDownloadVoucher,
	greenChoiceGuestVoucher,
	GreenChoiceIncentiveType,
	greenChoiceInvalidQRCodes,
	greenChoiceLastName,
	GreenChoiceMiscSettings,
	greenChoiceQrCode,
	greenChoiceQRCodeExcel,
	greenChoiceQRCodeOverview,
	GreenChoiceRoom,
	greenChoiceSettings,
	GreenChoiceVoucher,
	GreenChoiceVoucherDetails,
	greenChoiceVoucherDetails,
	greenChoiceVoucherValidation,
	ResolvedLanguage,
	RoomQRCode,
	GreenChoiceGuestDescription, greenChoiceShowDonationAmount, greenChoiceDonationAmount,
} from '@app/model';
import {DownloadUtil} from '../util/DownloadUtil';
import {format} from 'date-fns';
import {DateUtil} from '@app/util';

export class GreenChoiceService extends AbstractService {
	static INSTANCE = new GreenChoiceService();

	async getRoomsQRCodes(): Promise<RoomQRCode[]> {
		const res = await axios.get(this.getUrl(greenChoiceQRCodeOverview));
		return res.data;
	}

	async getInvalidRoomsQRCodes(): Promise<RoomQRCode[]> {
		return await axios
			.get(this.getUrl(greenChoiceInvalidQRCodes))
			.then((res: AxiosResponse<RoomQRCode[]>) => res.data);
	}

	async getRoomsQrCodeUrlsExcel(): Promise<void> {
		const fileName = 'qr-code-urls';
		const response = await axios.get(this.getUrl(greenChoiceQRCodeExcel), {
			responseType: 'blob',
		});
		const data = new Blob([response.data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		DownloadUtil.downloadFile(data, fileName);
	}

	getGreenChoiceGuestData(hotelId: string, id: string): Promise<GreenChoiceGuestData> {
		return axios
			.get(this.getUrl(greenChoiceGuest + '/' + hotelId + '/' + id))
			.then((res: AxiosResponse<GreenChoiceGuestData>) => res.data);
	}

	createGreenChoicePublic(
		hotelId: string,
		id: string,
		lastName: string,
		incentiveType: GreenChoiceIncentiveType
	): Promise<GreenChoiceGuestData> {
		const config = {params: {lastName: lastName, incentiveType: incentiveType}};
		return axios
			.post(this.getUrl(greenChoiceGuest + '/' + hotelId + '/' + id), null, config)
			.then((res: AxiosResponse<GreenChoiceGuestData>) => res.data);
	}

	createGreenChoice(
		id: string,
		lastName: string,
		date: Date,
		incentiveType: GreenChoiceIncentiveType
	): Promise<GreenChoiceGuestData> {
		const config = {
			params: {date: date, lastName: lastName, roomId: id, incentiveType: incentiveType},
		};
		return axios
			.post(this.getUrl(greenChoiceActive), null, config)
			.then((res: AxiosResponse<GreenChoiceGuestData>) => res.data);
	}

	getGreenChoiceRooms(date: Date): Promise<GreenChoiceRoom[]> {
		const config = {params: {date: date}};
		return axios
			.get(this.getUrl(greenChoiceActive), config)
			.then((res: AxiosResponse<GreenChoiceRoom[]>) => res.data);
	}

	updateGreenChoiceVoucher(id: string, received: boolean, redeemed: boolean): Promise<void> {
		return axios.put(this.getUrl(greenChoiceActive + '/' + id), null, {
			params: {redeemed: redeemed, received: received},
		});
	}

	updateGreenChoiceVoucherPublic(
		hotelId: string,
		uuid: string,
		voucherId: string,
		redeemed: boolean
	): Promise<void> {
		const config = {params: {redeemed: redeemed, hotelId: hotelId, uuid: uuid}};
		return axios.put(this.getUrl(greenChoiceVoucherValidation + '/' + voucherId), null, config);
	}

	deleteGreenChoiceEntry(id: string): Promise<void> {
		return axios.delete(this.getUrl(greenChoiceActive + '/' + id));
	}

	getGreenChoiceDescriptionPublic(
		hotelId: string,
		id: string,
		language: string
	): Promise<GreenChoiceGuestDescription> {
		const config = {params: {language: language}};
		return axios
			.get(this.getUrl(greenChoiceDescriptionGuest + '/' + hotelId + '/' + id), config)
			.then((res: AxiosResponse<GreenChoiceGuestDescription>) => res.data);
	}

	async getGreenChoiceDescription(
		language: ResolvedLanguage,
		options: GreenChoiceDescriptionOption[]
	): Promise<GreenChoiceDescription[]> {
		const config = {params: {options: options.join(',')}};
		return axios
			.get(this.getUrl(greenChoiceDescription + '/' + language), config)
			.then((res: AxiosResponse<GreenChoiceDescription[]>) => res.data);
	}

	async getGreenChoiceCutoff() {
		const res: AxiosResponse<GreenChoiceCutoff> = await axios.get(
			this.getUrl(greenChoiceCutoff)
		);
		return res.data;
	}

	setGreenChoiceDescription(
		language: ResolvedLanguage,
		descriptionList: GreenChoiceDescription[]
	): Promise<string> {
		return axios.put(this.getUrl(greenChoiceDescription + '/' + language), descriptionList);
	}

	async getGreenChoiceSettings(): Promise<GreenChoiceMiscSettings> {
		const res = await axios
			.get(this.getUrl(greenChoiceSettings));
		return res.data;
	}

	async setGreenChoiceEmail(email: string) {
		return await axios.put(this.getUrl(greenChoiceEmail), null, {params: {email: email}});
	}

	async setDigitalVoucherPin(pin: string): Promise<void> {
		return await axios
			.put(this.getUrl(greenChoiceDigitalVoucherPin), null, {
				params: {pin: pin},
			})
			.then((res: AxiosResponse<void>) => res.data);
	}

	async getVoucher(
		hotelId: string,
		id: string,
		voucherId: string,
		language: ResolvedLanguage
	): Promise<GreenChoiceVoucher> {
		return axios
			.get(
				this.getUrl(greenChoiceGuestVoucher + '/' + hotelId + '/' + id + '/' + voucherId),
				{params: {language: language}}
			)
			.then((res: AxiosResponse<GreenChoiceVoucher>) => res.data);
	}

	async downloadVoucherPdf(
		hotelId: string,
		id: string,
		voucherId: string,
		language: ResolvedLanguage
	) {
		const response = await axios.get(
			this.getUrl(
				greenChoiceGuestDownloadVoucher + '/' + hotelId + '/' + id + '/' + voucherId
			),
			{
				responseType: 'blob',
				params: {
					language: language,
				},
			}
		);
		const data = new Blob([response.data], {
			type: 'application/pdf',
		});
		DownloadUtil.downloadFile(data, 'voucher');
	}

	async getGreenChoiceVoucherDetails(
		hotelId: string,
		id: string,
		voucherId: string
	): Promise<GreenChoiceVoucherDetails> {
		return axios
			.get(
				this.getUrl(greenChoiceVoucherDetails + '/' + hotelId + '/' + id + '/' + voucherId)
			)
			.then((res: AxiosResponse<GreenChoiceVoucherDetails>) => res.data);
	}

	async compareGreenChoiceVoucherPin(
		hotelId: string,
		uuid: string,
		pinToCheck: string
	): Promise<boolean> {
		return axios
			.get(this.getUrl(greenChoiceDigitalVoucherPinPublic + '/' + hotelId + '/' + uuid), {
				params: {pin: pinToCheck},
			})
			.then((res: AxiosResponse<boolean>) => res.data);
	}

	async updateGreenChoiceCutoff(
		date: Date,
		mode: GreenChoiceCutoffMode
	): Promise<GreenChoiceCutoff> {
		return axios
			.put(this.getUrl(greenChoiceCutoff), null, {
				params: {
					time: format(date, 'HH:mm:ss'),
					mode: mode,
				},
			})
			.then((res: AxiosResponse<GreenChoiceCutoff>) => res.data);
	}

	async setGreenChoiceEmailTime(date: Date) {
		return axios.put(this.getUrl(greenChoiceEmailTime), null, {
			params: {
				time: format(date, 'HH:mm:ss'),
			},
		});
	}

	async updateGreenChoiceDonationAmount(donationAmount: number) {
		return axios.put(this.getUrl(greenChoiceDonationAmount), null, {
			params: {
				donationAmount: donationAmount,
			}
		});
	}

	async updateGreenChoiceShowDonationAmount(showDonationAmount: boolean) {
		return axios.put(this.getUrl(greenChoiceShowDonationAmount), null, {
			params: {
				showDonationAmount: showDonationAmount,
			}
		});
	}

	async verifyLastName(
		lastName: string,
		date: Date,
		hotelId: string,
		uuid: string
	): Promise<boolean> {
		return axios
			.get(this.getUrl(greenChoiceLastName + '/' + hotelId + '/' + uuid), {
				params: {
					lastName: lastName,
					date: DateUtil.toServiceDateString(date),
				},
			})
			.then((res: AxiosResponse<boolean>) => res.data);
	}

	async getActiveGreenChoiceData(
		hotelId: string,
		uuid: string,
		date: Date
	): Promise<ActiveGreenChoiceData> {
		return axios
			.get(this.getUrl(activeGreenChoiceData + '/' + hotelId + '/' + uuid), {
				params: {
					date: DateUtil.toServiceDateString(date),
				},
			})
			.then((res: AxiosResponse<ActiveGreenChoiceData>) => res.data);
	}

	async reassignGreenChoiceKey(keyId: string, newRoomId: string): Promise<void> {
		return axios.put(this.getUrl(greenChoiceQrCode + '/' + keyId), null, {
			params: {
				roomId: newRoomId,
			},
		});
	}

	async deleteGreenChoiceKey(keyId: string) {
		return axios.delete(this.getUrl(greenChoiceQrCode + '/' + keyId));
	}
}
