import React, {ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState} from 'react';
import {AuthenticationService, CountlyService} from '@app/services';
import {AlertSeverity, PasswordMode} from '@app/model';
import {SnackbarContext} from '@app/context';
import {Trans, useTranslation} from 'react-i18next';
import {AxiosError} from 'axios';
import {Button, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import zxcvbn from 'zxcvbn';
import {Box, Container, Typography} from '@mui/material';
import {VendorModal} from '../modal/VendorModal';

export function PasswordReset() {
	const {t} = useTranslation(['loginRegister']);
	const minStrength = 3;
	const thresholdLength = 7;
	const location = useLocation();

	const [password, setPassword] = useState<string>('');
	const [passwordRepeat, setPasswordRepeat] = useState<string>('');
	const [passwordStrength, setPasswordStrength] = useState<number>(0);
	const [mode, setMode] = useState<PasswordMode>(PasswordMode.NOT_FOUND);
	const [ref, setRef] = useState<string | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const countly = CountlyService.get();
	const authenticationService = AuthenticationService.get();
	const {showMessage} = useContext(SnackbarContext);

	const verifyRef = useCallback(
		(ref) => {
			authenticationService.verifyRef(ref).then(() => {
				setMode(PasswordMode.FORM);
				setRef(ref);
			});
		},
		[authenticationService]
	);

	useEffect(() => {
		const newRef = new URLSearchParams(location.search).get('ref');
		if (newRef) {
			verifyRef(newRef);
		}
	}, [verifyRef]);

	async function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		await authenticationService
			.changePassword(ref, password)
			.then(() => {
				showMessage(
					t('loginRegister:resetPassword.passwordChangedSnackBar'),
					AlertSeverity.SUCCESS
				);
				setMode(PasswordMode.SUCCESS);
			})
			.catch((err: AxiosError) => countly.error(err.message));
	}

	function validateStrength() {
		return password.length > thresholdLength && passwordStrength >= minStrength;
	}

	function validateForm() {
		return validateStrength() && validateRepeat();
	}

	function validateRepeat() {
		return password.length > 0 && password === passwordRepeat;
	}

	function handlePwChange(
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) {
		setPassword(e.target.value);
		setPasswordStrength(zxcvbn(e.target.value).score);
	}

	function handleChange(
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) {
		setPasswordRepeat(e.target.value);
	}

	function createPasswordLabel(result: number) {
		switch (result) {
			case 0:
				return 'Weak';
			case 1:
				return 'Weak';
			case 2:
				return 'Fair';
			case 3:
				return 'Good';
			case 4:
				return 'Strong';
			default:
				return 'Weak';
		}
	}

	function renderNotFound() {
		return (
			<>
				<Typography paragraph>{t('loginRegister:resetPassword.notFound1')}</Typography>
				<Typography paragraph>
					<Trans
						i18nKey={'loginRegister:resetPassword.notFound2'}
						components={{
							link1: <Link to="/login" />,
							link2: <Link to="/forgotpassword" />,
						}}
					/>
				</Typography>
			</>
		);
	}

	function renderSuccess() {
		return (
			<>
				<Typography paragraph>{t('loginRegister:resetPassword.success1')}</Typography>
				<Typography paragraph>
					<Trans
						i18nKey={'loginRegister:resetPassword.success2'}
						components={{link1: <Link to="/login" />}}
					/>
				</Typography>
			</>
		);
	}

	function renderForm() {
		return (
			<form data-testid="reset-password-form" onSubmit={(e) => handleSubmit(e)}>
				<FormGroup controlId="password">
					<FormLabel>{t('resetPassword.yourNewPassword')} *</FormLabel>
					<FormControl
						isValid={validateStrength()}
						isInvalid={!validateStrength()}
						type="password"
						value={password}
						onChange={handlePwChange}
						data-testid="password-field"
					/>
					<progress
						className={`password-strength-meter-progress strength-${createPasswordLabel(
							passwordStrength
						)}`}
						value={passwordStrength}
						max="4"
					/>
					<div className="valid-feedback">{t('resetPassword.validPwFeedback')}</div>
					<div className="invalid-feedback">{t('resetPassword.invalidPwFeedback')}</div>
				</FormGroup>
				<FormGroup controlId="passwordRepeat">
					<FormLabel>{t('resetPassword.pleaseRepeat')} *</FormLabel>
					<FormControl
						data-testid="repeat-password-field"
						isValid={validateRepeat()}
						isInvalid={!validateRepeat()}
						type="password"
						value={passwordRepeat}
						onChange={handleChange}
					/>
					<div className="valid-feedback">{t('resetPassword.validPwFeedback')}</div>
					<div className="invalid-feedback">
						{t('resetPassword.invalidRepetitionFeedback')}
					</div>
				</FormGroup>
				<Button as={Link} to="/login" block type="button">
					{t('common:button.cancel')}
				</Button>
				<Button
					block
					disabled={!validateForm()}
					type="submit"
					data-testid="reset-password-button"
				>
					{t('resetPassword.setPwButton')}
				</Button>
			</form>
		);
	}

	function renderModes() {
		switch (mode) {
			case PasswordMode.NOT_FOUND:
				return <>{renderNotFound()}</>;
			case PasswordMode.SUCCESS:
				return <>{renderSuccess()}</>;
			case PasswordMode.FORM:
				return <>{renderForm()}</>;
			default:
				return <> </>;
		}
	}

	return (
		<div className="login">
			<Box
				component="img"
				src="/assets/logo_login_page.png"
				alt="Roomgrid logo"
				sx={{height: '75px', width: '242px', mt: 10, mb: 7}}
				onClick={() => setIsModalOpen(true)}
			/>
			<Container maxWidth="xs">
				<h4>{t('loginRegister:resetPassword.title')}</h4>
				<Box sx={{mt: '2rem'}}>{renderModes()}</Box>
			</Container>
			<VendorModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</div>
	);
}
