import React from 'react';
import {Container, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

export function Imprint() {
	const theme = useTheme();
	const {t} = useTranslation(['legalNotice']);

	return (
		<Container maxWidth="md" sx={{mt: 8}}>
			<Typography variant="h4" component="h3" sx={{mb: 2, color: theme.palette.primary.dark}}>
				{t('legalNotice:title')}
			</Typography>
			<Typography paragraph>{t('legalNotice:5tmg')}</Typography>
			<Typography paragraph>
				Thoesch GmbH
				<br />
				Mühlgasse 7
				<br />
				82335 Berg
				<br />
				{t('legalNotice:phone')}: +43 664 1622960
			</Typography>
			<Typography paragraph>
				{t('legalNotice:commercialRegister')}: HRB 188126
				<br />
				{t('legalNotice:registerCourt')}: Amtsgericht München
			</Typography>
			<Typography paragraph>
				{t('legalNotice:representedBy')}
				<br />
				Adrian Huwyler, Bernhard Rieder, Christian Ey, Daniel Frank
			</Typography>
		</Container>
	);
}
