import React, {useState} from 'react';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import {Box, FormLabel, Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {OptionalObjectSchema} from 'yup/lib/object';
import {useTranslation} from 'react-i18next';
import {GreenChoiceIncentiveType, ResolvedLanguage} from '@app/model';
import {RequiredStringSchema} from 'yup/lib/string';
import {AnyObject} from 'yup/lib/types';
import {GreenChoiceActivationPageIncentiveSelector} from '../pages/activation-page/incentive-type-selector/GreenChoiceActivationPageIncentiveSelector';
import {MixedSchema} from 'yup/lib/mixed';
import {DateAndConfirmationCheckBox} from '../pages/activation-page/date-and-confirmation-checkbox/DateAndConfirmationCheckBox';
import {GreenChoiceIncentiveTypeEditor} from '@app/model';

export interface GreenChoiceLastNameFormValues {
	lastName: string;
	incentiveType: GreenChoiceIncentiveType | undefined;
}

interface GreenChoiceLastNameFormProps {
	date: Date;
	isSubmitButtonLoading: boolean;
	shouldDisableFields: boolean;
	isEditMode: boolean;
	activeIncentiveTypes: GreenChoiceIncentiveTypeEditor[];
	language: ResolvedLanguage;
	validationSchema: OptionalObjectSchema<{
		lastName: RequiredStringSchema<string | undefined, AnyObject>;
		incentiveType?: MixedSchema<
			GreenChoiceIncentiveType | undefined,
			AnyObject,
			GreenChoiceIncentiveType
		>;
	}>;
	isLockedPage?: boolean;
	infoText?: JSX.Element;
	bottomText?: JSX.Element;
	onSubmit(
		values: GreenChoiceLastNameFormValues,
		actions: FormikHelpers<GreenChoiceLastNameFormValues>
	): void;
}

export function GreenChoiceLastNameAndIncentiveTypeForm(props: GreenChoiceLastNameFormProps) {
	const {
		date,
		onSubmit,
		validationSchema,
		language,
		activeIncentiveTypes,
		shouldDisableFields,
		isEditMode,
		isSubmitButtonLoading,
		infoText,
		isLockedPage,
		bottomText,
	} = props;

	const {t} = useTranslation(['greenChoice']);
	const [isChecked, setIsChecked] = useState<boolean>(false);

	function getIncentiveTypeInitialValue() {
		const greenChoiceIncentiveTypes = activeIncentiveTypes
			.map((editor) => editor.type);
		if (greenChoiceIncentiveTypes
			.includes(GreenChoiceIncentiveType.DONATION)) {
			return GreenChoiceIncentiveType.DONATION;
		} else if (greenChoiceIncentiveTypes.length > 1) {
			return undefined;
		} else if (greenChoiceIncentiveTypes.length == 1) {
			return greenChoiceIncentiveTypes[0];
		} else {
			return GreenChoiceIncentiveType.NONE;
		}
	}

	return (
		<Formik
			initialValues={{lastName: '', incentiveType: getIncentiveTypeInitialValue()}}
			onSubmit={(
				values,
				actions: FormikHelpers<{
					lastName: string;
					incentiveType: undefined | GreenChoiceIncentiveType;
				}>
			) =>
			{
				onSubmit(values, actions)}
			}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{(
				formikProps: FormikProps<{
					lastName: string;
					incentiveType: undefined | GreenChoiceIncentiveType;
				}>
			) => (
				<Form>
					{infoText}
					{activeIncentiveTypes.length > 1 ? (
						<GreenChoiceActivationPageIncentiveSelector
							activeIncentiveTypes={activeIncentiveTypes}
							formikProps={formikProps}
							language={language}
							isEditMode={isEditMode}
						/>
					) : null}
					<Stack spacing={1}>
						<Box sx={{mt: 2, mb: 4, display: 'flex', flexDirection: 'column'}}>
							<FormLabel sx={{textAlign: 'left', mb: 1}}>
								{t('greenChoice:guestView.activationPage.lastNamePrompt', {
									lng: language,
								})}
							</FormLabel>
							<TextField
								fullWidth
								name="lastName"
								label={t('greenChoice:guestView.activationPage.lastName', {
									lng: language,
								})}
								disabled={shouldDisableFields}
								type="text"
								value={formikProps.values.lastName}
								error={Boolean(formikProps.errors.lastName)}
								helperText={formikProps.errors.lastName}
								onChange={formikProps.handleChange}
							/>
						</Box>
						{!isLockedPage && (
							<DateAndConfirmationCheckBox
								date={date}
								language={language}
								isChecked={isChecked}
								setIsChecked={setIsChecked}
								shouldDisableFields={shouldDisableFields}
							/>
						)}
						<LoadingButton
							loading={isSubmitButtonLoading}
							variant="contained"
							type="submit"
							color="success"
							sx={{p: 2}}
							disabled={shouldDisableFields || (!isChecked && !isLockedPage)}
						>
							{isLockedPage
								? t('greenChoice:guestView.lockedPage.button', {
										lng: language,
								  })
								: t('greenChoice:guestView.activationPage.cancelNow', {
										lng: language,
								  })}
						</LoadingButton>
						{bottomText}
					</Stack>
				</Form>
			)}
		</Formik>
	);
}
