import React, {
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {HeaderViewWithSettings} from '@app/shared';
import {
	Box,
	CircularProgress,
	Container,
	Stack,
	SxProps,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {GreenChoiceCustomDescriptionCard} from './green-choice-custom-description-card/GreenChoiceCustomDescriptionCard';
import {TenantService} from '@app/services';
import {AxiosResponse} from 'axios';
import {
	AlertSeverity,
	AppSettingsData,
	Authority,
	GreenChoiceCutoff,
	GreenChoiceMiscSettings,
} from '@app/model';
import {GreenChoiceService} from '@app/services';
import {GreenChoiceMiscSettingsCard} from './green-choice-misc-settings/GreenChoiceMiscSettingsCard';
import {SnackbarContext} from '@app/context';
import {useAuthorization} from '@app/hooks';
import {GreenChoiceMiscSettingsContextProvider} from "../../../context/green-choice-misc-settings/GreenChoiceMiscSettingsContextProvider";

export const greenChoiceCardSX: SxProps = {
	minWidth: 275,
	width: '50%',
	mr: 1,
	overflow: 'visible',
};

export const greenChoiceCardMediumSX: SxProps = {
	minWidth: 275,
	width: '100%',
	mr: 0,
	mb: 1,
	overflow: 'visible',
};

export function GreenChoiceSettings() {
	const {t} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

	const hasEditGreenChoiceSettingsAuthority = useAuthorization(
		Authority.EDIT_GREEN_CHOICE_SETTINGS
	);

	const [availableLanguages, setAvailableLanguages] = useState<string[] | undefined>(undefined);
	const [cutoff, setCutoff] = useState<GreenChoiceCutoff | undefined>(undefined);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [sendEmailAt, setSendEmailAt] = useState<string | undefined>(undefined);
	const [showDonationAmountInEmail, setShowDonationAmountInEmail] = useState<boolean>(false);
	const [donationAmountInCents, setDonationAmountInCentsInCents] = useState<number>(500);
	const [pin, setPin] = useState<string | undefined>(undefined);
	const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(true);

	const tenantService = TenantService.get();
	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const getAvailableLanguages = useCallback(() => {
		tenantService
			.getAppSettings()
			.then((res: AxiosResponse<AppSettingsData>) =>
				setAvailableLanguages(res.data.availableLanguages)
			);
	}, [tenantService]);

	const getGreenChoiceSettings = useCallback(() => {
		greenChoiceService
			.getGreenChoiceSettings()
			.then((res: GreenChoiceMiscSettings) => {
				setEmail(res.greenChoiceEmail);
				setPin(res.digitalVoucherPin);
				setCutoff(res.cutoff);
				setSendEmailAt(res.sendGreenChoiceEmailAt);
				setShowDonationAmountInEmail(!!res.showDonationAmountInEmail)
				setDonationAmountInCentsInCents(res.donationAmountInCents)
				setIsLoadingSettings(false);
			})
			.catch(() => {
				setIsLoadingSettings(false);
				showMessage(t('common:error.commonError'), AlertSeverity.ERROR);
			});
	}, [greenChoiceService]);

	useEffect(() => {
		getAvailableLanguages();
		getGreenChoiceSettings();
	}, [getAvailableLanguages, getGreenChoiceSettings]);

	function renderContent() {
		return (
			<div>
				<Stack flexDirection={isMediumScreen ? 'column' : 'row'} mb={1}>
					<GreenChoiceCustomDescriptionCard
						availableLanguages={availableLanguages as string[]}
					/>
					<GreenChoiceMiscSettingsCard
						setSendEmailAt={setSendEmailAt}
						setPin={setPin}
						pin={pin}
						setEmail={setEmail}
						email={email}
						cutoff={cutoff}
						setCutoffTime={setCutoff}
						sendEmailAt={sendEmailAt}
						showDonationAmountInEmail={showDonationAmountInEmail}
						donationAmountInCents={donationAmountInCents}
						setDonationAmountInCents={setDonationAmountInCentsInCents}
					/>
				</Stack>
			</div>
		);
	}

	return hasEditGreenChoiceSettingsAuthority ? (
		<GreenChoiceMiscSettingsContextProvider>
			<Box className="App-content">
				<Container maxWidth="xl">
					<HeaderViewWithSettings
						buttonName={t('common:overview')}
						isAuthorized={true}
						path="/green-choice"
						icon={<FormatListBulletedIcon/>}
					/>
					{availableLanguages && !isLoadingSettings ? renderContent() : <CircularProgress/>}
				</Container>
			</Box>
		</GreenChoiceMiscSettingsContextProvider>
	) : null;
}
