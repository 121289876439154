import React, {useContext, useState} from 'react';
import {ModalWrapper} from '../../../../../modal/ModalWrapper';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, Box, Button, TextField, Typography, useMediaQuery, useTheme} from '@mui/material';
import * as yup from 'yup';
import {Form, Formik, FormikProps} from 'formik';
import {EnvironmentSelect} from './fields/EnvironmentSelect';
import {RegionSelect} from './fields/RegionSelect';
import {OhipEnvironment, OhipModalValues, User} from '@app/model';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {VendorService} from '@app/services';
import {PmsContext, UserContext} from '@app/context';
import {LoadingButton} from '@mui/lab';

interface ConnectOhipModalProps {
	isModalOpen: boolean;
	onCloseModal: () => void;
}

export function ConnectOhipModal(props: ConnectOhipModalProps) {
	const {isModalOpen, onCloseModal} = props;
	const {t} = useTranslation(['tenantAdmin']);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [loading, setLoading] = useState<boolean>(false);

	const pmsContext = useContext(PmsContext);
	const userContext = useContext(UserContext);

	const vendorService = VendorService.get();

	const initialValues: OhipModalValues = {
		environment: '',
		region: {key: '', label: ''},
		tenantCode: '',
		hotelId: '',
		loginUrl: '',
	};

	const validateOhipInformation = yup.object({
		environment: yup.string().required(t('tenantAdmin:pms.ohip.modal.validation.required')),
		region: yup
			.object()
			.shape({
				key: yup.string(),
				label: yup
					.string()
					.trim()
					.required(t('tenantAdmin:pms.ohip.modal.validation.required')),
			})
			.required(t('tenantAdmin:pms.ohip.modal.validation.required')),
		tenantCode: yup.string().required(t('tenantAdmin:pms.ohip.modal.validation.required')),
		hotelId: yup.string().required(t('tenantAdmin:pms.ohip.modal.validation.required')),
		loginUrl: yup.string().required(t('tenantAdmin:pms.ohip.modal.validation.required')),
	});

	const informativeAlert = (
		<Alert severity="info">
			<Typography sx={{fontSize: '0.875rem'}}>
				{t('tenantAdmin:pms.ohip.modal.helperText')}
			</Typography>
		</Alert>
	);

	async function handleSubmit(values: OhipModalValues) {
		setLoading(true);
		const connectionRequestInfo = {
			environment: values.environment as unknown as OhipEnvironment,
			region: values.region.key,
			tenantCode: values.tenantCode,
			hotelId: values.hotelId,
			loginUrl: values.loginUrl,
		};
		const vendorSettings = await vendorService.requestOhipConnection(connectionRequestInfo);
		const user = {...userContext.loggedInUser} as User;
		if (user) {
			user.vendorSettings = vendorSettings;
			userContext.setLoggedInUser(user);
		}
		pmsContext.setActiveVendor(vendorSettings.vendorId);
		userContext.setVendorStatus(vendorSettings.status);
		setLoading(false);
		onCloseModal();
	}

	function getContent() {
		return (
			<>
				{informativeAlert}
				<Formik
					validationSchema={validateOhipInformation}
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validateOnChange={false}
					validateOnBlur={false}
				>
					{(formikProps: FormikProps<OhipModalValues>) => (
						<Form>
							<EnvironmentSelect formikProps={formikProps} />
							<RegionSelect formikProps={formikProps} />
							<TextField
								size="small"
								sx={{mt: 2}}
								fullWidth
								onChange={formikProps.handleChange}
								value={formikProps.values.tenantCode}
								error={Boolean(formikProps.errors.tenantCode)}
								helperText={formikProps.errors.tenantCode}
								name="tenantCode"
								label={t('tenantAdmin:pms.ohip.modal.fields.tenant.label')}
							/>
							<TextField
								size="small"
								sx={{mt: 2}}
								fullWidth
								onChange={formikProps.handleChange}
								value={formikProps.values.hotelId}
								error={Boolean(formikProps.errors.hotelId)}
								helperText={formikProps.errors.hotelId}
								name="hotelId"
								label={t('tenantAdmin:pms.ohip.modal.fields.hotelId.label')}
							/>
							<TextField
								size="small"
								sx={{mt: 2, mb: 4}}
								fullWidth
								onChange={formikProps.handleChange}
								value={formikProps.values.loginUrl}
								error={Boolean(formikProps.errors.loginUrl)}
								helperText={formikProps.errors.loginUrl}
								name="loginUrl"
								label={t('tenantAdmin:pms.ohip.modal.fields.loginURL.label')}
							/>
							<Typography fontSize="0.875rem">
								<Trans
									i18nKey="tenantAdmin:pms.ohip.modal.needHelp"
									components={{
										link1: <a href="mailto:support@thoesch-conversational.ai" />,
									}}
								/>
							</Typography>
							<Box
								mt={3}
								display="flex"
								flexDirection={isSmallScreen ? 'column' : 'row'}
								justifyContent="right"
							>
								<Button
									variant="outlined"
									onClick={onCloseModal}
									sx={{mr: isSmallScreen ? 0 : 1, mb: isSmallScreen ? 1 : 0}}
								>
									{t('common:button.cancel')}
								</Button>
								<LoadingButton
									loading={loading}
									variant="contained"
									startIcon={<SendRoundedIcon />}
									type="submit"
								>
									{t('common:button.submit')}
								</LoadingButton>
							</Box>
						</Form>
					)}
				</Formik>
			</>
		);
	}

	return (
		<ModalWrapper
			size="md"
			title={t('tenantAdmin:pms.ohip.modal.title')}
			content={getContent()}
			open={isModalOpen}
			onClose={onCloseModal}
		/>
	);
}
