import React from 'react';
import {IconButton, TextField} from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {MaximumDate, DatePickerRange} from '@app/model';
import {DatePicker} from '@mui/x-date-pickers';
import {DateUtil} from '../../../util/DateUtil';
import {addDays, addMonths, subDays, subMonths} from 'date-fns';
import {isNaN} from 'formik';
import {useTranslation} from 'react-i18next';

interface ArrowsAndDateProps {
	setDateShown: React.Dispatch<React.SetStateAction<Date>>;
	dateShown: Date;
	mode: DatePickerRange;
	disableFuture: boolean;
	maxDate?: MaximumDate;
}

export function ArrowsAndDate(props: ArrowsAndDateProps) {
	const {dateShown, mode, disableFuture, setDateShown, maxDate} = props;
	const {i18n} = useTranslation();

	function isToday() {
		const today = new Date();

		if (mode === DatePickerRange.MONTH) {
			return (
				dateShown.getMonth() === today.getMonth() &&
				dateShown.getFullYear() === today.getFullYear()
			);
		} else {
			return DateUtil.isSameDate(dateShown, today);
		}
	}

	function isTomorrow() {
		const tomorrow = addDays(new Date(), 1);
		return DateUtil.isSameDate(dateShown, tomorrow);
	}

	function prev() {
		let date = dateShown;
		if (mode === DatePickerRange.MONTH) {
			date = subMonths(date, 1);
		} else {
			date = subDays(date, 1);
		}
		setDateShown(date);
	}

	function next() {
		let date = dateShown;
		if (mode === DatePickerRange.MONTH) {
			date = addMonths(date, 1);
		} else if (mode === DatePickerRange.DAY) {
			date = addDays(date, 1);
		}
		setDateShown(date);
	}

	function shouldDisableNextArrow() {
		if (maxDate) {
			if (mode === DatePickerRange.DAY && maxDate === MaximumDate.TOMORROW) {
				return isTomorrow();
			}
			return isToday();
		}
	}

	function handleDatePickerChange(newValue: Date | null) {
		if (newValue) {
			// Adjust the date to UTC 00:00 time regardless
			// whether input to date picker is by keyboard or picked by mouse
			const newDate =
				newValue.getUTCHours() !== 0
					? new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60 * 1000)
					: newValue;
			if ((newDate as unknown) instanceof Date && !isNaN(newDate)) {
				setDateShown(newDate);
			}
		}
	}

	function getDatePickerPlaceholder(): string {
		if (i18n.language.startsWith('de')) {
			if (mode === DatePickerRange.MONTH) {
				return 'Monat Jahr';
			}
			return 'tt.mm.jjjj';
		}
		if (mode === DatePickerRange.MONTH) {
			return 'month year';
		}
		return 'dd/mm/yyyy';
	}

	return (
		<DatePicker
			views={mode === DatePickerRange.MONTH ? ['year', 'month'] : ['year', 'month', 'day']}
			openTo={mode === DatePickerRange.MONTH ? 'month' : 'day'}
			onChange={handleDatePickerChange}
			disableFuture={disableFuture}
			value={dateShown}
			renderInput={(params) => (
				<>
					<IconButton onClick={() => prev()} sx={{mr: 0.25}}>
						<ChevronLeftRoundedIcon />
					</IconButton>
					<TextField
						{...params}
						size="small"
						sx={{width: '11.5em'}}
						margin="none"
						inputProps={{
							...params.inputProps,
							placeholder: getDatePickerPlaceholder(),
						}}
					/>
					<IconButton
						disabled={shouldDisableNextArrow()}
						onClick={() => next()}
						sx={{ml: 0.25}}
					>
						<ChevronRightRoundedIcon />
					</IconButton>
				</>
			)}
		/>
	);
}
