import React from 'react';
import {FlagUtil} from '@app/util';
import {AlertSeverity, Flag, GreenChoiceIncentiveType, User} from '@app/model';

export interface GreenChoiceMiscSettingsContextValue {
	isClickATreeSwitchOn: boolean;
	isNoIncentiveSwitchOn: boolean;
	isVoucherSwitchOn: boolean;
	isCustom1SwitchOn: boolean;
	selectedIncentiveType: GreenChoiceIncentiveType;
	setIsVoucherSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedIncentiveType: React.Dispatch<React.SetStateAction<GreenChoiceIncentiveType>>;
	setIsClickATreeSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
	setIsNoIncentiveSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
	setIsCustom1SwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
	handleSwitchChange(
		flag: Flag,
		isFlagOn: boolean,
		showMessage: (messageToShow: string, severity: AlertSeverity) => void
	): Promise<void>;
	handleFlagChange(
		flag: Flag,
		isFlagOn: boolean,
		showMessage: (messageToShow: string, severity: AlertSeverity) => void
	): Promise<User | null>;
}

const GreenChoiceMiscSettingsContext = React.createContext<GreenChoiceMiscSettingsContextValue>({
	isClickATreeSwitchOn: FlagUtil.hasEnabledFlag(null, Flag.GREEN_CHOICE_CLICK_A_TREE),
	isNoIncentiveSwitchOn: FlagUtil.hasEnabledFlag(null, Flag.GREEN_CHOICE_NO_INCENTIVE),
	isVoucherSwitchOn:
		FlagUtil.hasEnabledFlag(null, Flag.GREEN_CHOICE_DIGITAL_VOUCHER) ||
		FlagUtil.hasEnabledFlag(null, Flag.GREEN_CHOICE_PHYSICAL_VOUCHER),
	isCustom1SwitchOn: FlagUtil.hasEnabledFlag(null, Flag.GREEN_CHOICE_CUSTOM_1),
	selectedIncentiveType: GreenChoiceIncentiveType.NONE,
	setIsVoucherSwitchOn: () => null,
	setSelectedIncentiveType: () => null,
	handleSwitchChange: () => Promise.resolve(),
	handleFlagChange: async () => null,
	setIsClickATreeSwitchOn: () => null,
	setIsNoIncentiveSwitchOn: () => null,
	setIsCustom1SwitchOn: () => null,
});

export {GreenChoiceMiscSettingsContext};
