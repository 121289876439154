import React from 'react';
import {GreenChoiceIncentiveType, GreenChoiceRoom} from '@app/model';
import {SvgIcon, Tooltip, Typography} from '@mui/material';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import ScreenshotRoundedIcon from '@mui/icons-material/ScreenshotRounded';
import clickATreeLogo3 from '../visuals/assets/green-choice/click_a_tree_logo_3.png';
import {TFunction} from 'i18next';

export class GreenChoiceUtil {
	static renderIncentiveTypeIcon(greenChoiceRoom: GreenChoiceRoom, t: TFunction) {
		switch (greenChoiceRoom.incentiveType) {
			case GreenChoiceIncentiveType.VOUCHER_PHYSICAL:
				return (
					<Tooltip
						title={t(
							'greenChoice:settings.miscSettings.incentives.voucher.physicalVoucher'
						)}
						arrow
						placement="right"
					>
						<SvgIcon>
							<ReceiptLongTwoToneIcon />
						</SvgIcon>
					</Tooltip>
				);
			case GreenChoiceIncentiveType.VOUCHER_DIGITAL:
				return (
					<Tooltip
						title={t(
							'greenChoice:settings.miscSettings.incentives.voucher.digitalVoucher.label'
						)}
						arrow
						placement="right"
					>
						<SvgIcon>
							<ScreenshotRoundedIcon />
						</SvgIcon>
					</Tooltip>
				);
			case GreenChoiceIncentiveType.DONATION:
				return (
					<Tooltip
						title={t('greenChoice:settings.miscSettings.incentives.clickATree.label')}
						arrow
						placement="right"
					>
						<img src={clickATreeLogo3} style={{height: '24px', width: '28px'}} />
					</Tooltip>
				);
			case GreenChoiceIncentiveType.CUSTOM_1:
				return (
					<Tooltip
						title={t('greenChoice:settings.miscSettings.incentives.custom1.label')}
						arrow
						placement="right"
					>
						<SvgIcon>
							<AutoAwesomeTwoToneIcon />
						</SvgIcon>
					</Tooltip>
				);
			case GreenChoiceIncentiveType.NONE:
			default:
				return (
					<Typography sx={{fontSize: '0.875rem'}}>
						{t('greenChoice:settings.miscSettings.incentives.none.label')}
					</Typography>
				);
		}
	}
}
