import {
	Box,
	Button,
	Collapse,
	FormControlLabel,
	FormGroup,
	IconButton,
	Switch,
	TableCell,
	TableRow,
} from '@mui/material';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import {PmsConnectivityLabel} from '../pms-connectivity-label/PmsConnectivityLabel';
import React from 'react';
import {Feature, Flag, SuperAdminSettings, TenantChoiceView} from '@app/model';
import {useTranslation} from 'react-i18next';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import {TenantService} from '@app/services';
import Checkbox from '@mui/material/Checkbox';

interface TenantTableRowProps {
	tenant: TenantChoiceView;
	loadUsers: (tenant: TenantChoiceView) => void;
}

export function TenantTableRow({tenant, loadUsers}: TenantTableRowProps) {
	const tenantService = TenantService.get();

	const {t} = useTranslation(['admin', 'tenantAdmin']);
	const [displaySettings, setDisplaySettings] = React.useState<boolean>(false);
	const [tenantDetails, setTenantDetails] = React.useState<SuperAdminSettings | undefined>(
		undefined
	);

	async function handleSettingsButtonClick() {
		if (!displaySettings) {
			const res = await tenantService.getSuperAdminSettings(tenant.id);
			setTenantDetails(res.data);
			setDisplaySettings(true);
		} else {
			setDisplaySettings(false);
		}
	}

	async function handleTestHotelChange() {
		if (tenantDetails && !tenantDetails.testHotel) {
			const res = await tenantService.addFlagAsSuperAdmin(Flag.TEST_HOTEL, tenant.id);
			setTenantDetails(res.data);
		} else {
			const res = await tenantService.deleteFlagAsSuperAdmin(Flag.TEST_HOTEL, tenant.id);
			setTenantDetails(res.data);
		}
	}

	async function handleHotelInactiveChange() {
		if (tenantDetails && !tenantDetails.hotelActive) {
			const res = await tenantService.deleteFlagAsSuperAdmin(Flag.HOTEL_INACTIVE, tenant.id);
			setTenantDetails(res.data);
		} else {
			const res = await tenantService.addFlagAsSuperAdmin(Flag.HOTEL_INACTIVE, tenant.id);
			setTenantDetails(res.data);
		}
	}

	async function handleGreenChoiceChange() {
		if (tenantDetails && !tenantDetails.greenChoiceActive) {
			const res = await tenantService.addFeatureAsSuperAdmin(Feature.GREEN_CHOICE, tenant.id);
			setTenantDetails(res.data);
		} else {
			const res = await tenantService.deleteFeatureAsSuperAdmin(
				Feature.GREEN_CHOICE,
				tenant.id
			);
			setTenantDetails(res.data);
		}
	}

	return (
		<>
			<TableRow key={`tenant-table-row-${tenant.id}`}>
				<TableCell>{tenant.hotelName} {tenant.hotelActive ? '' : 'INACTIVE'}</TableCell>
				<TableCell>
					<Button
						variant="contained"
						size="small"
						startIcon={<PersonPinIcon />}
						onClick={() => loadUsers(tenant)}
					>
						{t('admin:superAdmin.impersonate')}
					</Button>
				</TableCell>
				<TableCell>
					<PmsConnectivityLabel tenant={tenant} />
				</TableCell>
				<TableCell sx={{display: 'flex', justifyContent: 'flex-end'}}>
					<IconButton onClick={handleSettingsButtonClick}>
						<SettingsTwoToneIcon />
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					sx={{
						paddingBottom: 0,
						paddingTop: 0,
					}}
					colSpan={4}
				>
					<Collapse in={displaySettings} timeout="auto" unmountOnExit>
						<Box sx={{paddingTop: 2, paddingBottom: 2}}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={tenantDetails?.testHotel}
											onChange={handleTestHotelChange}
										/>
									}
									label={t('admin:superAdmin.testHotel')}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={tenantDetails?.hotelActive}
											onChange={handleHotelInactiveChange}
										/>
									}
									label={t('admin:superAdmin.hotelActive')}
								/>
								<FormControlLabel
									control={
										<Switch
											onChange={handleGreenChoiceChange}
											checked={tenantDetails?.greenChoiceActive}
										/>
									}
									label={t('tenantAdmin:app.greenChoice.label')}
								/>
								{t('tenantAdmin:hotel.email')}: {tenantDetails?.hotelEmail ? tenantDetails?.hotelEmail : '---'}
							</FormGroup>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}
