import React from 'react';
import {SxProps, TableCell, TableRow, useTheme} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {DeleteGreenChoiceButton} from '../../delete-button/DeleteGreenChoiceButton';
import {GreenChoiceIncentiveType, GreenChoiceRoom} from '@app/model';
import {useTranslation} from 'react-i18next';
import {GreenChoiceUtil} from '../../../../../util/GreenChoiceUtil';

interface GreenChoiceRoomRowProps {
	greenChoiceRoom: GreenChoiceRoom;
	handleOnChangeCheckbox(id: string, recieved: boolean, redeemed: boolean): void;
	handleDeleteModalOpen(roomLabel: string, lastName: string, greenChoiceID: string): void;
}

export function GreenChoiceRoomRow(props: GreenChoiceRoomRowProps) {
	const {
		greenChoiceRoom,
		handleOnChangeCheckbox,
		handleDeleteModalOpen,
	} = props;
	const {t} = useTranslation(['greenChoice']);
	const theme = useTheme();

	const textColorSX: SxProps = {
		fontSize: '0.875rem',
		color: greenChoiceRoom.voucherRedeemedAt
			? theme.palette.text.disabled
			: theme.palette.text.primary,
		wordBreak: 'break-word',
	};

	const tableRowSX: SxProps = {
		backgroundColor: greenChoiceRoom.voucherRedeemedAt
			? theme.palette.action.disabledBackground
			: undefined,
	};

	return (
		<TableRow sx={tableRowSX}>
			<TableCell sx={textColorSX}>{greenChoiceRoom.roomLabel}</TableCell>
			<TableCell sx={textColorSX}>{greenChoiceRoom.lastName}</TableCell>
			<TableCell>{GreenChoiceUtil.renderIncentiveTypeIcon(greenChoiceRoom, t)}</TableCell>
			<TableCell align="right">
				{greenChoiceRoom.incentiveType === GreenChoiceIncentiveType.VOUCHER_PHYSICAL ? (
					<Checkbox
						checked={!!greenChoiceRoom.voucherIssuedAt}
						size="small"
						onClick={() =>
							handleOnChangeCheckbox(
								greenChoiceRoom.greenChoiceId,
								!greenChoiceRoom.voucherIssuedAt,
								!!greenChoiceRoom.voucherRedeemedAt
							)
						}
					/>
				) : null}
			</TableCell>
			<TableCell align="right">
				{greenChoiceRoom.incentiveType === GreenChoiceIncentiveType.DONATION ||
				greenChoiceRoom.incentiveType === GreenChoiceIncentiveType.CUSTOM_1 ||
				greenChoiceRoom.incentiveType === GreenChoiceIncentiveType.NONE ? null : (
					<Checkbox
						checked={!!greenChoiceRoom.voucherRedeemedAt}
						size="small"
						onClick={() =>
							handleOnChangeCheckbox(
								greenChoiceRoom.greenChoiceId,
								!!greenChoiceRoom.voucherIssuedAt,
								!greenChoiceRoom.voucherRedeemedAt
							)
						}
					/>
				)}
			</TableCell>
			<TableCell sx={{maxWidth: 60}}>
				<DeleteGreenChoiceButton
					handleDeleteModalOpen={handleDeleteModalOpen}
					greenChoiceRoom={greenChoiceRoom}
				/>
			</TableCell>
		</TableRow>
	);
}
